import React from "react";
import { ReactComponent as ShoppingIcon } from "../../assets/shopping-bag.svg";
import "./cart-icon.styles.scss";

export const DisabledCartIcon = () => (
  <div className="empty-cart-icon">
    <ShoppingIcon className="empty-shopping-icon"></ShoppingIcon>
    <span className="empty-item-count"></span>
  </div>
);

