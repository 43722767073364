import React, { useState } from "react";
import Chatbot from "react-chatbot-kit";
import { ConditionallyRender } from "react-util-kit";
import config from "../bots/docsbot/config";
import MessageParser from "../bots/docsbot/MessageParser";
import ActionProvider from "../bots/docsbot/ActionProvider";
import { ReactComponent as ButtonIcon } from "../assets/icons/robot.svg";
import "./chatbot.scss";

const ChatbotIcon = () => {
  const [showChatbot, toggleChatbot] = useState(false);

  return (
    <div>
      <div className="app-chatbot-container" style={{ fontFamily: "Arial", fontWeight: "bold" }}>
        <ConditionallyRender ifTrue={showChatbot} show={<Chatbot config={config} messageParser={MessageParser} actionProvider={ActionProvider} />} />
      </div>
      <button className="app-chatbot-button" onClick={() => toggleChatbot((prev) => !prev)} aria-label="chatbot">
        <ButtonIcon className="app-chatbot-button-icon" />
      </button>
    </div>
  );
};

export default ChatbotIcon;
