// Working hours

import React, { useEffect } from "react";
import { FadeIn } from "react-anim-kit";
// import { ConditionallyRender } from "react-util-kit";
// import Gist from "react-gist";

// import { ReactComponent as ActionProviderOverview } from "../../../../../assets/img/actionprovider-overview.svg";

// import styles from "./deals.styles.css";
// import "./deals.styles.css";
import styled from "styled-components";

// import InformationBox from "../../InformationBox/InformationBox";

// list-style: https://developer.mozilla.org/en-US/docs/Web/CSS/list-style

const Content = styled.span`
  text-align: left;
  color: black;
  
`;

const ActionProviderDocs = ({ setState }) => {
  useEffect(() => {
    setState((state) => ({
      ...state,
      infoBox: "bank account",
    }));
  }, [setState]);

  // const showActionProviderInfoBox = infoBox === "bank";

  // ❌ Source: https://getemoji.com/#food-drink
  const text = "Maybank\nAccount name: Matt Kerr\nAccount number: 101010-10101-11";
  let newText = text.split("\n").map((i) => {
    return <li style={{ listStyle: "none", lineHeight: "1" }}> {i} </li>;
  });

  return (
    <div>
      <FadeIn down by={200} delayBy={0.10} easeTiming={1}>
        <br/>
        <Content>{newText}</Content>
        <br/>
      </FadeIn>
    </div>
  );
};

export default ActionProviderDocs;
