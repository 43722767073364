import { all, call, put, takeLatest } from "redux-saga/effects";
import swal from "sweetalert";
import { auth, createUserProfileDocument, getCurrentUser, googleProvider } from "../../firebase/firebase.utils";
import { signInFailure, signInSuccess, signOutFailure, signOutSuccess, signUpFailure, signUpSuccess } from "./user.actions";
import UserActionTypes from "./user.types";
// import { Redirect } from "react-router";
// import { useHistory } from "react-router-dom";

// Refactoring
export function* getSnapshotFromUserAuth(userAuth, additionalData) {
  try {
    const userRef = yield call(createUserProfileDocument, userAuth, additionalData);
    const userSnapshot = yield userRef.get();
    // put(): puts things back into our regular redux flow
    yield put(signInSuccess({ id: userSnapshot.id, ...userSnapshot.data() }));
  } catch (error) {
    swal("Error 404", "Unable to retrieve user account information.");
    // window.alert("Sign in failed!");
    yield put(signInFailure(error));
  }
}

export function* signInWithGoogle() {
  try {
    const { user } = yield auth.signInWithPopup(googleProvider);
    yield getSnapshotFromUserAuth(user);
    swal("Signed in successfully", "Welcome to Sausage KL Café & Deli");
  } catch (error) {
    swal("Ouch!", "Unable to sign in via your Google account. Please try again later.");
    // window.alert("Sign in failed!");
    yield put(signInFailure(error));
  }
}

export function* signInWithEmail({ payload: { email, password } }) {
  try {
    const { user } = yield auth.signInWithEmailAndPassword(email, password);
    swal("Signed in successfully", "Welcome to Sausage KL Café & Deli", {
      icon: "success",
    });
    // window.alert("You are successfully signed in.");
    yield getSnapshotFromUserAuth(user);
    // window.location.href = "/";
    // history.push("/");
  } catch (error) {
    swal({
      title: "Incorrect email or password",
      text: "Please try again",
      dangerMode: "true",
      icon: "warning",
    });
    // window.alert("Incorrect email or password. Please try again.");
    yield put(signInFailure(error));
  }
}

export function* isUserAuthenticated() {
  try {
    const userAuth = yield getCurrentUser();
    if (!userAuth) return;
    yield getSnapshotFromUserAuth(userAuth);
  } catch (error) {
    yield put(signInFailure(error));
  }
}

export function* signOut() {
  swal("Signed out successfully", "Wish to see you again", {
    icon: "success",
  });
  // window.alert("Signed out successfully.");
  try {
    yield auth.signOut();
    yield put(signOutSuccess());
  } catch (error) {
    yield put(signOutFailure(error));
  }
}

export function* signUp({ payload: { email, password, displayName, phoneNumber, deliveryPostalCode, deliveryState, deliveryStreet } }) {
  try {
    const { user } = yield auth.createUserWithEmailAndPassword(email, password);
    let userEmail = email;
    // window.alert(`Welcome! Your email address is ` + userEmail + ".");
    swal({
      title: "Welcome!",
      text: "Your email address is " + userEmail,
      icon: "success",
    });
    yield put(signUpSuccess({ user, additionalData: { displayName, phoneNumber, deliveryPostalCode, deliveryState, deliveryStreet } }));
  } catch (error) {
    window.alert(error.message);
    yield put(signUpFailure(error));
  }
}

export function* signInAfterSignUp({ payload: { user, additionalData } }) {
  yield getSnapshotFromUserAuth(user, additionalData);
}

export function* onGoogleSignInStart() {
  yield takeLatest(UserActionTypes.GOOGLE_SIGN_IN_START, signInWithGoogle);
}

export function* onEmailSignInStart() {
  yield takeLatest(UserActionTypes.EMAIL_SIGN_IN_START, signInWithEmail);
}

export function* onCheckUserSession() {
  yield takeLatest(UserActionTypes.CHECK_USER_SESSION, isUserAuthenticated);
}

export function* onSignOutStart() {
  yield takeLatest(UserActionTypes.SIGN_OUT_START, signOut);
}

export function* onSignUpStart() {
  yield takeLatest(UserActionTypes.SIGN_UP_START, signUp);
}

export function* onSignUpSuccess() {
  yield takeLatest(UserActionTypes.SIGN_UP_SUCCESS, signInAfterSignUp);
}

/*********/
export function* userSagas() {
  yield all([call(onGoogleSignInStart), call(onEmailSignInStart), call(onCheckUserSession), call(onSignOutStart), call(onSignUpStart), call(onSignUpSuccess)]);
}
