// middleware is an array
import { applyMiddleware, createStore } from 'redux';
// Debugging
import logger from 'redux-logger';
// redux persist
import { persistStore } from 'redux-persist';
import createSagaMiddleware from 'redux-saga';
import rootReducer from './root-reducer';
import rootSaga from './root-saga';
// 
const sagaMiddleware = createSagaMiddleware();

const middlewares = [sagaMiddleware];

// ENV = environment variable (Heroku can set)
// can access through 'process.env' 
// hover to NODE_ENV for more details (e.g. build switches to production)
// if hosted in local host, it is switched to development
if (process.env.NODE_ENV === 'development') {
  middlewares.push(logger);
}

// return value = applyMiddleware 
// ...middlewares = spread in all of the methods or values in the array
// more scalable
export const store = createStore(rootReducer, applyMiddleware(...middlewares));

sagaMiddleware.run(rootSaga);

// persistor (create new)
export const persistor = persistStore(store);
// Put into index.js - <provider></provider>
export default { store, persistor };