import React, { lazy, Suspense, useEffect } from "react";
import { connect } from "react-redux";
import { Redirect, Route, Switch } from "react-router-dom";
import { createStructuredSelector } from "reselect";
import "../node_modules/font-awesome/css/font-awesome.min.css";
import "./App.scss";
import { ReactComponent as Logo } from "./assets/sausage-kl-logo-3.svg";
import ChatbotIcon from "./chatbot/components/chatbot.component.jsx";
import "./chatbot/components/chatbot.scss";
// import NotFound from "./pages/not-found/NotFound.component";
import ErrorBoundary from "./components/error-boundaries/error-boundary.component";
import MainFooter from "./components/footer/footer.component";
import Header from "./components/header/header-component/index";
import ScrollToTop from "./components/scroll-to-up/ScrollToTop";
import Spinner from "./components/spinner/spinner.component";
import { WhatsappIcon } from "./components/whatsapp/whatsapp";
import { GlobalStyle } from "./global.styles";
import CheckoutPage from "./pages/checkout/checkout.component";
import { checkUserSession } from "./redux/user/user.actions";
import { selectCurrentUser } from "./redux/user/user.selectors";
const HomePage = lazy(() => import("./pages/homepage/homepage.component"));
const ProductsPage = lazy(() => import("./pages/shop/shop.component"));
const SignInAndSignUpPage = lazy(() => import("./pages/sign-in-and-sign-up/sign-in-and-sign-up.component"));
const AboutUs = lazy(() => import("./pages/about-us/AboutUs"));
// const Delivery = lazy(() => import("./pages/delivery/delivery.component.jsx"));
// const NotFound = lazy(() => import("./pages/not-found/NotFound.component.jsx"));

const App = ({ checkUserSession, currentUser }) => {
  //unsubscribeFromAuth = null;
  // it is rendered when currentUser updates
  useEffect(() => {
    // invoke only the first time when we pass in (componentDidMount)
    checkUserSession();
  }, [checkUserSession]);

  return (
    <div>
      <GlobalStyle />
      {/* Header */}
      <Header logo={<Logo />} navPosition="center" />
      <Switch>
        <ErrorBoundary>
          <Suspense fallback={<Spinner />}>
            <ScrollToTop />
            <Route exact path="/" component={HomePage} />
            {/* (Disabled) Shop is only accessible after login 
          <Route path="/products" render={() => (currentUser ? <Route to="/products" component={ProductsPage}></Route> : <SignInAndSignUpPage />)} />
          */}
            <Route path="/products" component={ProductsPage} />
            <Route exact path="/checkout" render={() => (currentUser ? <Route to="/checkout" component={CheckoutPage}></Route> : <SignInAndSignUpPage />)} />
            <Route exact path="/sign-in" render={() => (currentUser ? <Redirect to="/"></Redirect> : <SignInAndSignUpPage />)} />
            {/*  */}
            <Route exact path="/about-us" component={AboutUs} />
            {/*  */}
            {/* <Route exact path="/delivery" component={Delivery} /> */}
          </Suspense>
        </ErrorBoundary>
      </Switch>
      {/* Chatbot */}
      <ChatbotIcon />
      {/*  */}
      <WhatsappIcon />
      <MainFooter />
    </div>
  );
}; // End of App class

// Redirect
// Destructure user changed to createStructuredSelector (shorter)
const mapStateToProps = createStructuredSelector({
  currentUser: selectCurrentUser,
});

const mapDispatchToProps = (dispatch) => ({
  checkUserSession: () => dispatch(checkUserSession()),
});

// second argument = dispatchProps
// null because no need any state from reducer
export default connect(mapStateToProps, mapDispatchToProps)(App);
