// import React from 'react';

const INITIAL_STATE = {
  // Array of objects
  sections: [
    {
      title: 'Homemade Sausages',
      imageUrl: '/img/products-directory/cover-italian.png',
      id: 1,
      linkUrl: 'products/sausages'
    },
    {
      title: 'Homemade Delights',
      imageUrl: '/img/products-directory/scotch-eggs.png',
      id: 2,
      linkUrl: "products/delights",
    },
    {
      title: 'The Meats',
      imageUrl: '/img/products-directory/back-bacon.png',
      id: 3,
      linkUrl: 'products/meats'
    },
  ]
}

const directoryReducer = (state = INITIAL_STATE, action) => {
  switch(action.type) {
    default:
        return state;
  }
}

export default directoryReducer;