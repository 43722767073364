// function to get two functions 
// state - initial state / last state
// receive action - just an object that has type (string value) ~ what specific action, we can name it as we want

import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
// actual local storage
import storage from 'redux-persist/lib/storage';
import cartReducer from './cart/cart.reducer';
import directoryReducer from './directory/directory.reducer';
import shopReducer from './shop/shop.reducer';
import userReducer from './user/user.reducer';


// JSON object for redux persist
const persistConfig = {
  key: 'root',
  storage, 
  // any reducers we used
  whitelist: ['cart']
}

const rootReducer = combineReducers({ 
  // giant object
  user: userReducer, 
  cart: cartReducer,
  directory: directoryReducer,
  shop: shopReducer
})

// pass in the object where the key goes to the actual reducer we want
export default persistReducer(persistConfig, rootReducer);