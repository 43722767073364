// utility functions allow us to keep our files clean and organize functions that we may need in multiple files in one location
// related to cart redux code
// import React, { useState } from 'react';

export const addItemToCart = (cartItems, cartItemToAdd) => {
  const existingCartItem = cartItems.find(
    // if found, it will add to the const, otherwise undefined
    (cartItem) => cartItem.id === cartItemToAdd.id
  );

  if (existingCartItem) {
    // return new array (state), so components will re-render properly
    return cartItems.map(
      (cartItem) =>
        cartItem.id === cartItemToAdd.id
          ? // quantity has to be declared
            { ...cartItem, quantity: cartItem.quantity + 1 }
          : cartItem // store the new cart item into shopping cart
    );
  }
  // If false in line 6
  // give object ...cartItemToAdd base quantity of 1, thus
  // whenever we add our items into cart, we can reference that quantity value
  // that we have added to our objects when we store them
  // ! quantity value gets attached the first time since the 'if' block wont run
  // when it is a new item
  return [...cartItems, { ...cartItemToAdd, quantity: 1 }];
};

export const removeItemFromCart = (cartItems, cartItemToRemove) => {
  const existingCartItem = cartItems.find((cartItem) => cartItem.id === cartItemToRemove.id);

  if (existingCartItem.quantity === 1) {
    return cartItems.filter((cartItem) => cartItem.id !== cartItemToRemove.id);
  }
  return cartItems.map((cartItem) => (cartItem.id === cartItemToRemove.id ? { ...cartItem, quantity: cartItem.quantity - 1 } : cartItem));
};

// export const removeItemFromCartAfterCheckout = (cartItems, cartItemToRemove) => {
//   // const existingCartItem = cartItems.find((cartItem) => cartItem.id === cartItemToRemove.id);
//   // const existingCartItem = cartItems.find((cartItem) => cartItem.id === cartItemToRemove.id);
//   // console.log("clearing items from cart.....");
//   // console.log(existingCartItem);
//   // console.log(cartItems);
//   return cartItems;
// };
