import React from "react";
import styled from "styled-components";
import './styles.scss';


const CustomSpan = styled.div`
  align-self: center;
`;

const Tripadvisor = () => {
  return (
    <div>
      <script
        sync="true"
        src="https://www.jscache.com/wejs?wtype=certificateOfExcellence&amp;uniq=387&amp;locationId=15042368&amp;lang=en_MY&amp;year=2020&amp;display_version=2"
        data-loadtrk
        onLoad="this.loadtrk=true"
      ></script>
      <div id="TA_certificateOfExcellence387" className="TA_certificateOfExcellence">
        <a target="_blank" rel="noopener noreferrer" href="https://www.tripadvisor.com.my/Restaurant_Review-g298570-d15042368-Reviews-Sausage_KL_Cafe_Deli-Kuala_Lumpur_Wilayah_Persekutuan.html/#REVIEWS">
          <CustomSpan>
            <img src="https://www.tripadvisor.com.my/img/cdsi/img2/awards/v2/tchotel_2020_LL-14348-2.png" alt="TripAdvisor" className="widCOEImg customImg" id="CDSWIDCOELOGO" />
          </CustomSpan>
        </a>
      </div>
    </div>
  );
};

export default Tripadvisor;