import React from "react";
import ReactDOM from "react-dom";
// Redux (store and reducer)
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { PersistGate } from "redux-persist/integration/react";
import App from "./App";
import "./index.css";
import { persistor, store } from "./redux/store";

// import 'bootstrap/dist/css/bootstrap.min.css';

// Redux store documentation
// https://www.npmjs.com/package/redux-persist

ReactDOM.render(
  <Provider store={store}>
    <BrowserRouter>
      <PersistGate persistor={persistor}>
        <React.StrictMode>
          <App />
        </React.StrictMode>
      </PersistGate>
    </BrowserRouter>
  </Provider>,
  document.getElementById("root")
);
