// config object

// import firebase utility library from the app
import "firebase/analytics";
import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
// All things above are attached to the keyword 'firebase' at above

const config = {
  apiKey: "AIzaSyAqgRnWcdL5OJlbtEF8p2UsjCZI2X27MMM",
  authDomain: "crwn-db-f042c.firebaseapp.com",
  databaseURL: "https://crwn-db-f042c.firebaseio.com",
  projectId: "crwn-db-f042c",
  storageBucket: "crwn-db-f042c.appspot.com",
  messagingSenderId: "698731564684",
  appId: "1:698731564684:web:e07516d2310dbd7be6b5a9",
  measurementId: "G-B2BN8LZNW3",
};

firebase.initializeApp(config);
firebase.analytics();

// Pull the objects from firebase logged in user to off object (asynchronous action: API)
export const createUserProfileDocument = async (userAuth, additionalData) => {
  if (!userAuth) return; // not false (if the auth object does not exist)

  const userRef = firestore.doc(`users/${userAuth.uid}`);
  const snapShot = await userRef.get();
  // console.log(snapShot);
  // else query inside a fire store for the document to check existance
  // console.log(snapShot;
  // ! if no user
  if (!snapShot.exists) {
    const { displayName, email, phoneNumber, deliveryPostalCode, deliveryState, deliveryStreet } = userAuth;
    const createAt = new Date();
    try {
      await userRef.set({
        displayName,
        email,
        phoneNumber,
        deliveryPostalCode,
        deliveryState,
        deliveryStreet,
        createAt,
        ...additionalData,
      });
    } catch (error) {
      console.log("error creating user", error.message);
    }
  }
  return userRef;
};

/*  For storing data into FIREBASE whenever we want */

// export const addCollectionAndDocuments = async (collectionKey, objectsToAdd) => {
//   // create collection using collection key
//   const collectionRef = firestore.collection(collectionKey);
//   console.log(collectionRef);

//   const batch = firestore.batch();
//   // objectsToAdd is an array
//   objectsToAdd.forEach(obj => {
//     // firebase points unique id for us, instead of us put (obj.title) inside doc()
//     const newDocRef = collectionRef.doc();
//     // loop array and batch calls together
//     batch.set(newDocRef, obj);
//   })
//   // fire off batch call and return promises
//   return await batch.commit();
// }

/* Getting user stored cart data */
export const getUserCartRef = async (userId) => {
  const cartsRef = firestore.collection("carts").where("userId", "==", userId);
  const snapShot = await cartsRef.get();

  if (snapShot.empty) {
    const cartDocRef = firestore.collection("carts").doc();
    await cartDocRef.set({ userId, cartItems: [] });
    return cartDocRef;
  } else {
    return snapShot.docs[0].ref;
  }
};

/* Add user cart data into Firestore */
export const addCollectionAndDocuments = async (collectionKey, objectsToAdd) => {
  const collectionRef = firestore.collection(collectionKey);

  const batch = firestore.batch();
  objectsToAdd.forEach((obj) => {
    const newDocRef = collectionRef.doc();
    batch.set(newDocRef, obj);
  });

  return await batch.commit();
};

/* Update firestore by deleting a particular document or its sub-document */


/* Getting products data from Firebase */
export const convertCollectionsSnapshotToMap = (collections) => {
  const transformedCollection = collections.docs.map((doc) => {
    const { title, items, source } = doc.data();

    // "collections" from Firebase
    return {
      routeName: encodeURI(title.toLowerCase()),
      id: doc.id, // from redux
      title,
      items,
      // e.g. Homemade
      source,
    };
  });
  return transformedCollection.reduce((accumulator, collection) => {
    accumulator[collection.title.toLowerCase()] = collection;
    return accumulator;
  }, {});
};

//-----
export const getCurrentUser = () => {
  return new Promise((resolve, reject) => {
    const unsubscribe = auth.onAuthStateChanged((userAuth) => {
      unsubscribe();
      resolve(userAuth);
    }, reject);
  });
};

// For Google authentication
export const auth = firebase.auth();
export const firestore = firebase.firestore();
// Google authentication utility
// Definition: always pop up when we use Google auth
// provider for authentication and sign in
export const googleProvider = new firebase.auth.GoogleAuthProvider();
googleProvider.setCustomParameters({ prompt: "select_account" });

// It can be used for Twitter or other also
export const signInWithGoogle = () => auth.signInWithPopup(googleProvider);
export default firebase;
