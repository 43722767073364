import { default as React } from "react";
import { Nav, Navbar } from "react-bootstrap";
import { connect } from "react-redux";
import { LinkContainer } from "react-router-bootstrap";
import { Link } from "react-router-dom";
import { createStructuredSelector } from "reselect";
import styled from "styled-components";
import swal from "sweetalert";
import { selectCartHidden } from "../../../redux/cart/cart.selectors";
import { signOutStart } from "../../../redux/user/user.actions";
import { selectCurrentUser } from "../../../redux/user/user.selectors";
import CartDropdown from "../../cart-dropdown/cart-dropdown.component";
import { DisabledCartIcon } from "../../cart-icon/cart-icon-empty.component";
import CartIcon from "../../cart-icon/cart-icon.component";
import TripadvisorLogo from "../../tripadvisor-awards/header-logo.component";
import TripadvisorNav from "../../tripadvisor-awards/inside-navbar/header-logo.component";
import HeaderLogo from "./HeaderLogo";
import "./nav.css";

const TripadvisorContainer = styled.div`
  align-self: center;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;

  @media screen and (max-width: 600px) {
    display: none;
  }
`;

const Header = ({ logo, currentUser, hidden, signOutStart }) => {
  function handleSignOutStart() {
    swal({
      title: "Are you sure?",
      dangerMode: true,
      buttons: true,
      icon: "warning",
    }).then((wantSignOut) => {
      if (wantSignOut) {
        signOutStart();
      }
    });
  }

  return (
    <>
      <Navbar collapseOnSelect expand="lg" className="nav-color sticky-top mb-5">
        <LinkContainer to="/sign-in" className="mx-2">
          <Navbar.Brand>{<HeaderLogo logo={logo} />}</Navbar.Brand>
          {/* <HeaderLogo logo={logo}/> */}
        </LinkContainer>
        <TripadvisorContainer>
          <TripadvisorLogo />
        </TripadvisorContainer>
        {currentUser ? (
          <>
            <CartIcon />
            {hidden ? null : <CartDropdown />}
          </>
        ) : (
          <>
            <DisabledCartIcon />
          </>
        )}
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav" color="black" style={{ float: "right !important" }}>
          <Nav className="ml-auto">
            <TripadvisorNav />
            <LinkContainer to="/about-us" className="mx-3">
              <Nav.Link>About us</Nav.Link>
            </LinkContainer>
            <LinkContainer to="/products" className="my-2 mx-3">
              <Nav.Link>Products</Nav.Link>
            </LinkContainer>
            <a href="https://prettier-jones-267440.netlify.app/" className="my-2 mx-3 blog-class" target="_blank" rel="noopener noreferrer">
              Blog
            </a>
            <LinkContainer to="/sign-in" className="my-2 mx-3">
              <Nav.Link>
                {currentUser ? (
                  <span onClick={handleSignOutStart} style={{ color: "red" }}>
                    Sign out
                  </span>
                ) : 
                  "Sign in"
                }
              </Nav.Link>
            </LinkContainer>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    </>
  );
};

const mapStateToProps = createStructuredSelector({
  currentUser: selectCurrentUser,
  hidden: selectCartHidden,
});

const mapDispatchToProps = (dispatch) => ({
  signOutStart: () => dispatch(signOutStart()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Header);
