import React from "react";
import { createChatBotMessage } from "react-chatbot-kit";

import Overview from "./widgets/Overview/Overview";
import CafeMenu from "./widgets/docs/cafe-menu/cafe-menu.component";
import WorkingHours from "./widgets/docs/working-hours/working-hours.component";
import DeliveryMenu from "./widgets/docs/delivery-menu/delivery-menu.component";
// import WhatsApp from "./widgets/docs/tripadvisor/tripadvisor-logo.component";
import Deals from "./widgets/docs/deals/deals.component";
import Tripadvisor from "./widgets/docs/tripadvisor/tripadvisor-logo.component";
import BankAccount from "./widgets/docs/bank-account/bankAccount.component.jsx";
// import CustomChatMessage from './domain-components/custom-chat-message.component.jsx'

const botName = "Sausage KL";

const config = {
  botName: botName,
  lang: "no",
  customStyles: {
    botMessageBox: {
      // backgroundColor: "#E8E8EB",
    },
    chatButton: {
      // It is element style (not recommended)
      // backgroundColor: "#5ccc9d",
    },
  },
  initialMessages: [
    createChatBotMessage(
      `Hi I'm ${botName} Virtual Helper 😊`,
      {
        delay: 500,
        // To Overview component indicated below
      }
    ),
    createChatBotMessage(
      `Try me by asking your questions, or select a topic below.`,
      {
        delay: 2000,
        widget: "overview",
        // To Overview component indicated below
      }
    ),
    // createChatBotMessage(
    //   "...",
    //   {
    //     withAvatar: false,
    //     delay: 1000,
    //     widget: "overview",
    //   }
    // ),
  ],
  state: {
    gist: "",
    infoBox: "",
  },
  customComponents: {
    // botChatMessage: (props) => <CustomChatMessage {...props} />,
    // userChatMessage: (props) => <MyUserChatMessage {...props} />
  },
  // These affect widgets/ActionProvider.js
  widgets: [
    {
      widgetName: "overview",
      widgetFunc: (props) => <Overview {...props} />,
      mapStateToProps: ["gist"],
    },
    {
      widgetName: "cafeMenuWidget",
      widgetFunc: (props) => <CafeMenu {...props} />,
      mapStateToProps: ["gist", "infoBox"],
    },
    {
      widgetName: "workingHoursWidget",
      widgetFunc: (props) => <WorkingHours {...props} />,
      mapStateToProps: ["gist", "infoBox"],
    },
    {
      widgetName: "deliveryMenuWidget",
      widgetFunc: (props) => <DeliveryMenu {...props} />,
      mapStateToProps: ["gist", "infoBox"],
    },
    {
      widgetName: "dealsWidget",
      widgetFunc: (props) => <Deals {...props} />,
      mapStateToProps: ["gist", "infoBox"],
    },
    {
      widgetName: "tripadvisorWidget",
      widgetFunc: (props) => <Tripadvisor {...props} />,
      mapStateToProps: ["gist", "infoBox"],
    },
    // {
    //   widgetName: "bankAccountWidget",
    //   widgetFunc: (props) => <BankAccount {...props} />,
    //   mapStateToProps: ["gist", "infoBox"],
    // },
  ],
};

export default config;
