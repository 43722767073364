import React from "react";
import styled from "styled-components";
import "./styles.scss";


const CustomSpan = styled.div`
  margin-top: 1rem;
`;

const Tripadvisor = () => {
  return (
    <div>
      <script
        sync="true"
        src="https://www.jscache.com/wejs?wtype=certificateOfExcellence&amp;uniq=387&amp;locationId=15042368&amp;lang=en_MY&amp;year=2020&amp;display_version=2"
        data-loadtrk
        onLoad="this.loadtrk=true"
      ></script>
      <div id="TA_certificateOfExcellence387" class="TA_certificateOfExcellence">
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://www.tripadvisor.com.my/Restaurant_Review-g298570-d15042368-Reviews-Sausage_KL_Cafe_Deli-Kuala_Lumpur_Wilayah_Persekutuan.html/#REVIEWS"
        >
          <CustomSpan>
            <img src="https://www.tripadvisor.com.my/img/cdsi/img2/awards/v2/tchotel_2020_LL-14348-2.png" alt="TripAdvisor" class="widCOEImgChat" id="CDSWIDCOELOGO" />
          </CustomSpan>
        </a>
      </div>
    </div>
  );
};

export default Tripadvisor;

// <div id="TA_certificateOfExcellence387" class="TA_certificateOfExcellence">
//         <ul id="5OiIjM" class="TA_links QMZZUEAVT9c">
//             <li id="qENbpsQcU" class="bQpWVjOoHuYE"><a target="_blank"
//                     href="https://www.tripadvisor.com.my/Restaurant_Review-g298570-d15042368-Reviews-Sausage_KL_Cafe_Deli-Kuala_Lumpur_Wilayah_Persekutuan.html"><img
//                         src="https://www.tripadvisor.com.my/img/cdsi/img2/awards/v2/tchotel_2020_LL-14348-2.png"
//                         alt="TripAdvisor" class="widCOEImg" id="CDSWIDCOELOGO" /></a></li>
//         </ul>
//     </div>
//     <script async
//         src="https://www.jscache.com/wejs?wtype=certificateOfExcellence&amp;uniq=387&amp;locationId=15042368&amp;lang=en_MY&amp;year=2020&amp;display_version=2"
//         data-loadtrk onload="this.loadtrk=true"></script>
