import React from "react";

import styles from "./Options.module.css";

// import styled, { css } from "styled-components";

// const option = styled.button`
//   font-weight: bold;
//   border-radius: 16px;
//   padding: 11px;
//   border: 0.07rem solid #173e3f;
//   color: #1d1d1d;
//   font-size: 0.9rem;
//   margin: 4px 2px;
//   background: transparent;
// `;

// const buttonDisabled = false;

const Options = ({ options }) => {
  const markup = options.map((option) => (
    <button key={option.id} 
      className={styles.option} 
      onClick={option.handler}>
      {option.name}
    </button>
  ));

  return <div className={styles.options}>{markup}</div>;
};

export default Options;
