import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import s from "./header-logo.module.scss";

const LogoLink = styled(Link)`
  @media screen and (min-width: 990px) {
    &:hover {
      transform: scale(1.1);
    }
  }
`;

const HeaderLogo = ({ logo }) => {
  return (
    <LogoLink className={s.logo} to="/">
      {logo}
    </LogoLink>
  );
};

export default HeaderLogo;
