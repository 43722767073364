// Working hours

import React, { useEffect } from "react";
import { FadeIn } from "react-anim-kit";
// import { ConditionallyRender } from "react-util-kit";
// import Gist from "react-gist";

// import { ReactComponent as ActionProviderOverview } from "../../../../../assets/img/actionprovider-overview.svg";

// import styles from "./working-hours.styles.css";
// import styled from "styled-components";
import { makeStyles } from "@material-ui/core/styles";

// import InformationBox from "../../InformationBox/InformationBox";

const ActionProviderDocs = ({ setState }) => {
  useEffect(() => {
    setState((state) => ({
      ...state,
      infoBox: "working hours",
    }));
  }, [setState]);

  // const showActionProviderInfoBox = infoBox === "working hours";
  
  const liStyle = makeStyles(() => ({
    root: {
      marginLeft: "4%",
      margin: "1rem 0",
      fontSize: "14px",
      letterSpacing: "1px"
    }
  }));

  const classes = liStyle();

  // ❌ Source: https://getemoji.com/#food-drink
  const text = "🛎️ Monday: 9:00AM - 7:00PM" + "\n❌ Tuesday & Wednesday: CLOSED\n🛎️ Thursday: 9:00AM - 5:00PM\n🛎️ Friday: 9:00AM - 5:00PM\n🛎️ Saturday: 9:00AM - 5:00PM\n🛎️ Sunday: 9:00AM - 5:00PM\n🥰 🛎️ Public Holiday: 9:00AM - 3:00PM";
  
  let newText = text.split("\n").map((textContent) => {
    return <li style={{ listStyle:"none" }} className={classes.root}>{textContent}</li>;
  });

  return (
    <div >
      <FadeIn left by={300} delayBy={0.15} easeTiming={1.5}>
        <br/>
        <list>{newText}</list>
        <br/>
      </FadeIn>
    </div>
  );
};

export default ActionProviderDocs;
