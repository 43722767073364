// Food menu

class MessageParser {
  constructor(actionProvider) {
    this.actionProvider = actionProvider;
  }

  // from ActionProvider
  parse = (message) => {
    const lowerCase = message.toLowerCase();

    
    if (
      lowerCase.includes("cafemenu") ||  lowerCase.includes("café") ||
      lowerCase.includes("cafe") || lowerCase.includes("café") ||
      lowerCase.includes("cafe menu") || lowerCase.includes("café menu") 
    ) {
      return this.actionProvider.handleCafeMenuDocs();
    }

    // ** has to be changed
    if (lowerCase.includes("working hours") || lowerCase.includes("working") || lowerCase.includes("hours")) {
      return this.actionProvider.handleWorkingHoursDocs();
    }
    //
    if (lowerCase.includes("delivery") || lowerCase.includes("deliverymenu") 
    || lowerCase.includes("delivery menu")) {
      return this.actionProvider.handleDeliveryMenuDocs();
    }
    // 
    if (lowerCase.includes("deals")) {
      return this.actionProvider.handleDealsDocs();
    }
    // 
    if (lowerCase.includes("menu")) {
      return this.actionProvider.handleMenuDefault();
    }
    //
    if (lowerCase.includes("tripadvisor") || lowerCase.includes("writea review") || lowerCase.includes("writeareview") || lowerCase.includes("write a review") || lowerCase.includes("review") || lowerCase.includes("write") || lowerCase.includes("feedback")) {
      return this.actionProvider.handleTripadvisorDocs();
    }
    //
    // if (lowerCase.includes("bank") || lowerCase.includes("account")) {
    //   return this.actionProvider.handleBankAccountDocs();
    // }
    // 
    
    return this.actionProvider.handleDefault();
  };
}

export default MessageParser;
