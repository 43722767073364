import { createSelector } from 'reselect';
// there are 2 types of selectors - input selector and output selector
 
// Input selector (return a slice of it)
const selectCart = state => state.cart;

export const selectCartItems = createSelector(
  // 2 arguments: array and function that return the value we want
  [selectCart],
  (cart) => cart.cartItems
);

export const selectCartHidden = createSelector(
  [selectCart],
  cart => cart.hidden
)

export const selectCartItemsCount = createSelector(
  [selectCartItems],
  cartItems => cartItems.reduce((accumulatedQuantity, cartItem) => 
  accumulatedQuantity + cartItem.quantity, 0)
)

export const selectCartTotal = createSelector(
  [selectCartItems],
  cartItems => cartItems.reduce((accumulatedQuantity, cartItem) => 
  accumulatedQuantity + cartItem.quantity * cartItem.price, 0)
)