// Delivery menu

import React, { useEffect } from "react";
// import { ConditionallyRender } from "react-util-kit";

// import GistContainer from "../../GistContainer/GistContainer";

// import styles from "./delivery-menu.styles.css";
import { FadeIn } from "react-anim-kit";
import styled from "styled-components";

import deliveryMenuPNG from "./home-delivery-menu-14-jan-2021.jpg";

// const DownloadButton = styled.a`
//   color: blue;
//   display: inline;
//   text-align: center;
//   font-size: 1.2rem;
// `;

const DownloadButton2 = styled.a`
  color: blue;
  display: inline;
  text-align: center;
  font-size: 1.2rem;
  cursor: pointer;
`;

const text = "🚚\nDownload";
let newText = text.split("\n").map((i) => {
  return <li style={{ listStyle: "none" }}> {i}</li>;
});

const DeliveryMenu = ({ gist, setState }) => {
  useEffect(() => {
    setState((state) => ({ ...state, gist: "delivery menu" }));
  }, [setState]);

  return (
    <div>
      <FadeIn down by={300} delayBy={0.15} easeTiming={1}>
        {/* <DownloadButton href={deliveryMenuPNG} download="sausage_kl_delivery_menu.png"> */} 
        <br/>
        <br/>
        <DownloadButton2 onClick={()=> window.open(deliveryMenuPNG, "_blank")}>  
          <u>{newText}</u>
        </DownloadButton2>
        <p style={{ textAlign: 'center'}}>Last updated: 14 February 2021</p>
        {/* </DownloadButton> */}
        <br/>
        <br/>
        <br/>
      </FadeIn>

      {/* Pop-up info */}
      {/* 
      <a
        href="https://gist.github.com/FredrikOseberg/87795296efb67fe76fa05fc839d22e25"
        target="_blank"
        rel="noopener noreferrer"
        className={styles.configLink}
      >
        View example
      </a>
      <ConditionallyRender
        ifTrue={showActionProviderGist}
        show={<GistContainer gistId="af04e2e30407671412af08fa3c429409" />}
      />
      */}
    </div>
  );
};

export default DeliveryMenu;
