import React from "react";
import { FadeIn } from "react-anim-kit";
// import { ReactComponent as OverviewSVG } from "../../../../assets/img/bot-overview.svg";
import styles from "./Overview.module.css";
// Linking
import GeneralOptions from "../options/GeneralOptions/GeneralOptions";
// import styled, { css } from "styled-components";

const Overview = (props) => {
  
  return (
    <div className={styles.overview}>
      {/*
      <FadeIn left by={250} >
         <OverviewSVG className={styles.overviewSVG} />
       </FadeIn>
      */}
      {/* To ActionProvider.js */}
      <FadeIn right by={250} delayBy={0.5} easeTiming={1}>
        <GeneralOptions actionProvider={props.actionProvider} />
      </FadeIn>
    </div>
  );
};

export default Overview;
