import React from "react";
import Options from "../Options/Options";

// Options/Selections for users

const GeneralOptions = (props) => {
  const options = [
    {
      name: "Café menu", // messageparser
      handler: props.actionProvider.handleCafeMenuDocs,
      id: 1,
    },
    {
      name: "Delivery menu", // config
      handler: props.actionProvider.handleDeliveryMenuDocs,
      id: 2,
    },
    {
      name: "Working hours", // actionprovider
      handler: props.actionProvider.handleWorkingHoursDocs,
      id: 3,
    },
    {
      name: "Deals", // widgets
      handler: props.actionProvider.handleDealsDocs,
      id: 4,
    },
    {
      name: "Write a review", // widgets
      handler: props.actionProvider.handleTripadvisorDocs,
      id: 5,
    },
    // {
    //   name: "Bank account", // widgets
    //   handler: props.actionProvider.handleBankAccountDocs,
    //   id: 6,
    // },
  ];
  { /* Go to Overview.jsx */ }
  return <Options options={options} />;
};

// Go to Overview.jsx
export default GeneralOptions;
