import FacebookIcon from "@material-ui/icons/Facebook";
import InstagramIcon from "@material-ui/icons/Instagram";
import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
// $primary-light-blue: #8DB9ED;
// $primary-line-color: #ccc;
import "./styes.scss";


const Footer = styled.div`
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: center;
  background: white;
  margin-top: 10vh;
  width: 100%;
`;
// Main container
const Container = styled.div`
  font: 11px "futura", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: auto;
  flex-flow: column nowrap;
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: center;
  margin-bottom: 50px;
  margin-top: 100px;
  a {
  }
  ul {
    list-style: none;
    position: relative;
    left: -18px;
    color: #8db9ed;
    margin-top: auto;
    width: 100%;
    li {
      margin: 0 0 0 0;
      text-align: center;
      font: 1.3em "futura", sans-serif;
      > :hover {
        color: red;
        cursor: pointer;
      }
    }
    header {
      color: black;
      font: 2.3vw "futura", sans-serif;
    }
  }
  /*  */
  Section {
    width: 100%;
    align-items: center;
    justify-content: space-around;
    position: relative;
  }
`;

export default function MainFooter() {
  return (
    <Container>
      <Footer>
        <div className="footer-social-section flex-rw">
          <span className="footer-social-overlap footer-social-connect">CONNECT {/* <span className="footer-social-small">with</span>  */}WITH US</span>
          <span className="footer-social-overlap footer-social-icons-wrapper svg_div">
            <a href="https://www.facebook.com/sausagekl/" target="_blank" rel="noopener noreferrer">
              <FacebookIcon className="generic-anchor svg_icons" />
            </a>
            <a href="https://www.instagram.com/sausagekl/" target="_blank" rel="noopener noreferrer">
              <InstagramIcon className="generic-anchor svg_icons" />
            </a>
          </span>
        </div>

        <div className="flex-rw footer-navigation">
          <Link to="about-us" className="footer-navigation">
            <u>About us</u>
          </Link>
          <span className="crossy">|</span>
          <a href="https://www.blog.sausagekl.com/" className="footer-navigation" target="_blank" rel="noopener noreferrer">
            <u>Blog</u>
          </a>
          <span className="crossy">|</span>
          <Link to="/" className="footer-navigation" style={{ opacity: "20%", pointerEvents: "none" }}>
            <u>Legal</u>
          </Link>
          <span className="crossy">|</span>
          <Link to="/" className="footer-navigation" style={{ opacity: "20%", pointerEvents: "none" }}>
            <u>Cookies</u>
          </Link>
          <span className="crossy">|</span>

          <Link to="/" className="footer-navigation" style={{ opacity: "20%", pointerEvents: "none" }}>
            <u>Sitemap</u>
          </Link>

          <span className="crossy">|</span>
          <a
            href="https://www.tripadvisor.com.my/Restaurant_Review-g298570-d15042368-Reviews-Sausage_KL_Cafe_Deli-Kuala_Lumpur_Wilayah_Persekutuan.html/#REVIEWS"
            className="footer-navigation"
            target="_blank"
            rel="noopener noreferrer"
          >
            <u>Tripadvisor</u>
          </a>

          <span className="crossy">|</span>
          <Link to="/" className="footer-navigation" style={{ opacity: "20%", pointerEvents: "none" }}>
            <u>FAQ</u>
          </Link>

          {/* <span className="crossy">|</span> */}
          {/* <Link to="delivery" className="footer-navigation">
            <u>Delivery</u>
          </Link> */}
        </div>

        <div className="footer-bottom-section flex-rw">
          <div className="footer-bottom-wrapper">
            <i className="fa fa-copyright"></i> <div className="footer-address"> Sausage KL Café & Deli </div>
            {new Date().getFullYear()}{" "}
          </div>

          <div className="footer-bottom-wrapper">
            <div className="generic-anchor">Holiday Place, Jalan Ampang 55000 Kuala Lumpur, Malaysia.</div>
          </div>
        </div>
      </Footer>
    </Container>
  );
}
