import React from "react";
import "./styles.scss";

class ErrorBoundary extends React.Component {
  constructor() {
    super();

    this.state = {
      hasErrored: false,
    };
  }

  static getDerivedStateFromError(error) {
    // process the error
    return {
      hasErrored: true,
    };
  }

  // give access to error and info
  componentDidCatch(error, info) {
    console.log(error);
  }

  render() {
    if (this.state.hasErrored) {
      return (
        <>
          <div className="error-boundary">
            <h1>Something went wrong</h1>
          </div>
        </>
      );
    }
    return this.props.children;
  }
}

export default ErrorBoundary;
