import React, { useEffect } from "react";
import { FadeIn } from "react-anim-kit";
// import { ConditionallyRender } from "react-util-kit";
// import { Switch, Route, Redirect, Link } from "react-router-dom";
// import { ReactComponent as MessageParserOverview } from "../../../../../assets/img/message-parser-overview.svg";
import cafeMenuPNG from "./sausage_kl_cafe_menu.png"; // Better performance and quality
// import cafeMenuPDF from "./sausage_kl_cafe_menu.pdf";
// import styles from "./food-menu.styles.css";
// import InformationBox from "../../InformationBox/InformationBox";

import styled from "styled-components";

// const DownloadButton = styled.a`
//   color: blue;
//   display: inline;
//   text-align: center;
//   font-size: 1.2rem;
// `;

const DownloadButton2 = styled.a`
  color: blue;
  display: inline;
  text-align: center;
  font-size: 1.2rem;
  cursor: pointer;
`;

const CafeMenuDocs = ({ infoBox, setState }) => {
  useEffect(() => {
    setState((state) => ({
      ...state,
      infoBox: "cafe menu",
    }));
  }, [setState]);

  // const showFoodMenuInfoBox = infoBox === "cafe menu";

  const text = "📜\nDownload";
  let newText = text.split("\n").map((i) => {
    return <li style={{ listStyle: "none" }}> {i}</li>;
  });

  return (
    <div>
      <FadeIn down by={300} delayBy={0.15} easeTiming={1}>
        {/* <DownloadButton href={cafeMenuPNG} download="sausage_kl_cafe_menu.png"> */}
        <br/>
        <br/>
        <DownloadButton2 onClick={()=> window.open(cafeMenuPNG, "_blank")}>  
          <u>{newText}</u>
        </DownloadButton2>
        {/* </DownloadButton> */}
        <br/>
        <br/>
        <br/>
      </FadeIn>

      {/* Pop-up info */}
      {/* <ConditionallyRender
        ifTrue={showFoodMenuInfoBox}
        show={
          <InformationBox setState={setState}>
            <p className={styles.infoBoxParagraph}>
              You have to write your own messageparser. The beauty of this is
              that you have full control over how you want to parse messages
              from the user. You can make it as complex or simple as you want.
              The simplest version is to check for keywords with javascript
              .includes()
            </p>
            <p className={styles.infoBoxParagraph}>
              Once you pass the messageparser to the chatbot, it will initialize
              it with the actionprovider you provided, so that you can determine
              which action after you have parsed the message.
            </p>
          </InformationBox>
        }
      />
      */}
    </div>
  );
};

export default CafeMenuDocs;
