import "bootstrap/dist/css/bootstrap.min.css";
import emailjs from "emailjs-com";
import React, { useState } from "react";
import { Button } from "react-bootstrap";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import styled from "styled-components";
// Test sweetalert
import swal from "sweetalert";
import CheckoutItem from "../../components/checkout-item/checkout-item.component";
import Spinner from "../../components/spinner-checkout/spinner.component";
import { clearCart } from "../../redux/cart/cart.actions";
import { selectCartItems, selectCartTotal } from "../../redux/cart/cart.selectors";
import { selectCurrentUser } from "../../redux/user/user.selectors";
import "./checkout.styles.scss";
import CheckoutSuccesssMessage from "./checkout.success.component";
// import ScrollToTop from "../../components/ScrollToTop";
// --------------------------------
const Checkout = styled.div`
  margin-left: auto;
  margin-top: 20px;
`;

// -------- CheckoutPage
const CheckoutPage = ({ cartItems, total, user, clearItem, clearItemInFirebase }) => {
  {
    /* Sending email via Emailjs */
  }
  function sendEmail(e) {
    e.preventDefault();
    emailjs.sendForm("gmail", "template_omswqfl", e.target, "user_AFpZ09pctFUzpBRa5lKaE").then(
      (result) => {
        console.log(result.text);
      },
      (error) => {
        console.log(error.text);
      }
    );
  }
  // -------------------------------------------------------
  const [form, setForm] = useState({
    show_form: true, // should be true
  });
  const [successCheckoutMessage, setSuccessCheckoutMessage] = useState({
    show_message: false, // should be true
  });
  const [name, setName] = useState(user.displayName);
  const [phoneNumber, setPhoneNumber] = useState(user.phoneNumber);
  const [email, setEmail] = useState(user.email);
  const [remarks, setRemarks] = useState("");
  const [isChecked, setCheckbox] = useState(false);
  const [deliveryPostalCode, setDeliveryPostalCode] = useState(user.deliveryPostalCode);
  const [deliveryState, setDeliveryState] = useState(user.deliveryState);
  const [deliveryStreet, setDeliveryStreet] = useState(user.deliveryStreet);
  // const [paymentMethod, setPaymentMethod] = useState("");
  const [isHiddenCheckoutBtn, setCheckoutBtn] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const handleClick = () => setLoading(true);
  const [isCancelBtnDisabled, SetCancelBtnDisabled] = useState(false);

  function handleFormClick() {
    swal({
      title: "Proceed to checkout?",
      icon: "warning",
      buttons: {
        cancel: "Cancel",
        openForm: {
          text: "Sure",
          value: "openForm",
          className: "hoverEffect",
        },
      },
    }).then((value) => {
      switch (value) {
        case "openForm":
          setForm({
            ...form,
            show_form: !form.show_form,
          });
          setCheckoutBtn(true);
          break;
        default:
      }
    });
  }

  function simulateNetworkRequest() {
    return new Promise((resolve) => setTimeout(resolve, 3000));
  }

  function handleSubmitBtn() {
    handleClick();
    // Disable cancel button
    SetCancelBtnDisabled(!false);
    if (isChecked) {
      simulateNetworkRequest().then(() => {
        swal({
          title: "Order successfully!",
          text: "Kindly read the new messages provided",
          icon: "success",
        });
        setForm({
          ...form,
          show_form: !form.show_form, // set to false !form.show_form
        });
        clearItem(cartItems);
        // clearItemInFirebase(cartItems);

        // show successsful checkout message
        setSuccessCheckoutMessage({
          ...successCheckoutMessage,
          show_message: !successCheckoutMessage.show_message,
        });
      });
    }
  }

  function handleCancelBtn() {
    swal({
      title: "Cancel checkout?",
      icon: "warning",
      buttons: {
        cancel: "No",
        openForm: {
          text: "Yes",
          value: "openForm",
          className: "hoverEffect",
        },
      },
    }).then((value) => {
      switch (value) {
        case "openForm":
          setForm({
            ...form,
            show_form: !form.show_form, // set to false !form.show_form
          });
          setCheckoutBtn(false);
          // if (paymentMethod === "bankTransfer" || paymentMethod === "cashOnDelivery") {
          //   paymentMethod.selectedIndex = 0;
          //   console.log(setPaymentMethod("none"));
          //   // console.log(setPaymentMethod(selected));
          // }
          if (isChecked === true) {
            setCheckbox(!isChecked);
          }
          break;
        default:
      }
    });
  }
  // ---------- handleChange - handle form change according to the Total amount
  function handleNameChange(e) {
    setName(e.target.value);
  }
  function handlePhoneNumberChange(e) {
    setPhoneNumber(e.target.value);
  }
  function handleDeliveryPostalCodeChange(e) {
    setDeliveryPostalCode(e.target.value);
  }
  function handleDeliveryStateChange(e) {
    setDeliveryState(e.target.value);
    // console.log(e.target.value);
  }
  function handleDeliveryStreetChange(e) {
    setDeliveryStreet(e.target.value);
  }
  function handleEmailChange(e) {
    setEmail(e.target.value);
  }
  function handleCheckboxChange(e) {
    setCheckbox(!isChecked);
  }
  // function handlePaymentMethod(e) {
  //   setPaymentMethod(e.target.value);
  // }

  function isFormFilled() {
    // || paymentMethod === ""
    if (name === "" || phoneNumber === "" || deliveryPostalCode === "" || deliveryState === "" || deliveryStreet === "" || email === "" || isChecked === false) {
      return false;
    } else {
      return true;
    }
  }
  // -------------------------------------------------------
  return (
    <div className="checkout-page">
      <div className="checkout-header">
        <div className="header-block">
          <span className="product-header">Product</span>
        </div>
        <div className="header-block">
          <span>Description</span>
        </div>
        <div className="header-block">
          <span>Quantity</span>
        </div>
        <div className="header-block">
          <span>Price</span>
        </div>
        <div className="header-block">
          <span>Remove</span>
        </div>
      </div>
      {cartItems.map((cartItem) => (
        <CheckoutItem key={cartItem.name} cartItem={cartItem} />
      ))}
      {/* Checkout button */}
      <div className="total">
        <span>Total: RM {total}</span>
      </div>
      <Checkout>
        {total === 0 ? (
          <h1>
            <span role="img" aria-label="emoji">
              😔
            </span>
          </h1>
        ) : (
          <Button onClick={handleFormClick} hidden={isHiddenCheckoutBtn ? true : false} className="font-weight-bold">
            Checkout
          </Button>
        )}
      </Checkout>
      <div className="checkout-page_order">
        {/* false && true || false = false (don't hide form when it is clicked)
            true && true || false = true (hide form when it is not clicked yet) 
            true & false || true = true (hide form when total = 0)
        */}
        <form onSubmit={sendEmail} hidden={(form.show_form && total !== 0) || total === 0 ? true : false} className="needs-validation">
          <div className="pt-5 mx-auto">
            <div className="form-group mx-auto open-form-header">
              <span>For delivery, please fill up the order form 📝 😊</span>
            </div>
            <p>You can still add or delete item until you submit the form</p>
            <div className="pt-2 form-group mx-auto" style={{ marginLeft: "auto", marginRight: "auto" }}>
              <label>Name:</label>
              <span className="ml-3">
                <input type="text" name="user_name" className={"form-control " + (name === "" ? "is-invalid" : "is-valid")} value={name} onChange={handleNameChange} required />
              </span>
            </div>
            <div className="pt-2 form-group mx-auto">
              <label>Phone Number</label>
              <span className="ml-3">
                <input
                  type="text"
                  name="user_phone_number"
                  className={"form-control " + (phoneNumber === "" ? "is-invalid" : "is-valid")}
                  defaultValue={phoneNumber}
                  onChange={handlePhoneNumberChange}
                  required
                />
              </span>
            </div>
            {/* Portal code */}
            <div className="pt-2 form-group mx-auto">
              <label>Portal Code</label>
              <input
                type="text"
                name="user_portal_code"
                className={"form-control " + (deliveryPostalCode === "" ? "is-invalid" : "is-valid")}
                defaultValue={deliveryPostalCode}
                onChange={handleDeliveryPostalCodeChange}
                required
              />
            </div>
            {/* State (DDL) */}
            <div className="pt-2 form-group mx-auto">
              <label>State</label>
              <select
                name="user_delivery_state"
                className={"form-control " + (deliveryState === "" ? "is-invalid" : "is-valid")}
                defaultValue={deliveryState}
                onChange={handleDeliveryStateChange}
                required
              >
                <option name="none" value="">
                  None
                </option>
                <option name="kuala-lumpur">Kuala Lumpur</option>
                <option name="melaka">Melaka</option>
                <option name="negeri-sembilan">Negeri Sembilan</option>
                <option name="putrajaya">Putrajaya</option>
                <option name="selangor">Selangor</option>
              </select>
            </div>
            {/* Delivery street */}
            <div className="pt-2 form-group mx-auto">
              <label>Building, street, and etc...</label>
              <input
                type="text"
                name="user_delivery_street"
                className={"form-control " + (deliveryStreet === "" ? "is-invalid" : "is-valid")}
                defaultValue={deliveryStreet}
                onChange={handleDeliveryStreetChange}
                required
              />
            </div>
            {/* Email address */}
            <div className="pt-2 form-group mx-auto">
              <label>Email</label>
              <input type="email" name="user_email" className={"form-control " + (email === "" ? "is-invalid" : "is-valid")} defaultValue={email} onChange={handleEmailChange} required />
            </div>
            {/* Payment method */}
            {/* <div className="pt-2 form-group mx-auto">
              <label>Payment method</label>
              <select
                id="paymentMethodSelect"
                name="payment_method"
                className={"form-control " + (paymentMethod === "" ? "is-invalid" : "is-valid")}
                onChange={handlePaymentMethod}
                defaultValue=""
                required
              >
                <option name="none" value="" selected="selected">
                  None
                </option>
                <option name="bank-transfer" value="bankTransfer">
                  Bank transfer
                </option>
                <option name="cash-on-delivery" value="cashOnDelivery">
                  Cash on delivery
                </option>
              </select>
            </div> */}
            {/* Remarks */}
            <div className="pt-2 form-group mx-auto">
              <label>Remarks (Optional)</label>
              <textarea name="remarks" className="form-control" cols="1" rows="2" defaultValue={remarks} onChange={() => setRemarks(remarks)} />
            </div>
            <div className="pt-2 form-group mx-auto">
              <div className="form-check">
                <input className={"form-check-input " + (isChecked ? "is-valid" : "is-invalid")} type="checkbox" checked={isChecked} onChange={handleCheckboxChange} required />
                <label className="form-check-label" htmlFor="invalidCheck">
                  {/* {" "}
                  Agree to{" "}
                  <span>
                    <a href="#" className="text-primary">
                      terms and conditions
                    </a> */}
                  <span> Confirm order</span>{" "}
                </label>
              </div>
            </div>
            <div className="form-group mx-auto">
              <p className="alert-danger pl-1">{isFormFilled() ? "" : "The order form is not filled correctly or completely "}</p>
              <Button onClick={handleCancelBtn} className="btn-danger" disabled={isCancelBtnDisabled ? true : false}>
                {"Cancel"}
              </Button>
              <Button
                type="submit"
                disabled={isFormFilled() ? false : true}
                // hidden={isFormFilled() ? false : true}
                style={!isLoading ? null : { pointerEvents: "none" }}
                onClick={isFormFilled() ? handleSubmitBtn : null}
                className="ml-3"
              >
                {isLoading ? <Spinner /> : "Submit"}
              </Button>
            </div>
            <div className="pt-2 form-group mx-auto">
              {/* <label>Message</label> */}
              <textarea
                name="order_message"
                className="form-control"
                cols="30"
                rows="10"
                hidden={true}
                required
                defaultValue={
                  cartItems.map(
                    (cartItem, index) =>
                      "#" +
                      (index + 1) +
                      " Product: " +
                      cartItem.name +
                      " (Type: " +
                      cartItem.type +
                      ")" +
                      "\n" +
                      "~Unit: " +
                      cartItem.unit +
                      "\n" +
                      "~Quantity: " +
                      cartItem.quantity +
                      "\n" +
                      "~Price: " +
                      cartItem.quantity +
                      " x " +
                      cartItem.price +
                      ` = ` +
                      cartItem.quantity * cartItem.price +
                      "\n\n####"
                  ) +
                  " Total price: \nRM " +
                  total
                }
              ></textarea>
            </div>
          </div>
        </form>
        {/* false put a new component */}
        {successCheckoutMessage.show_message ? <CheckoutSuccesssMessage /> : ""}
        {/* End of Order form */}
      </div>
    </div>
  );
};

const mapStateToProps = createStructuredSelector({
  cartItems: selectCartItems,
  total: selectCartTotal,
  user: selectCurrentUser,
});

const mapDispatchToProps = (dispatch) => ({
  clearItem: (item) => dispatch(clearCart(item)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CheckoutPage);
