// Promotions

import React, { useEffect } from "react";
import { FadeIn } from "react-anim-kit";
// import { ConditionallyRender } from "react-util-kit";
// import Gist from "react-gist";

// import styles from "./tripadvisor.styles.css";
// import InformationBox from "../../InformationBox/InformationBox";
import styled from "styled-components";
// Tripadvisor widget
import Tripadvisor from "../../../../../../components/tripadvisor-awards/logo.component";

const TripadvisorLogoButton = styled.a`
  color: #00af87;
  display: inline;
  text-align: center;
  font-size: 1.1rem;
`;

const TripadvisorDocs = ({ infoBox, setState }) => {
  useEffect(() => {
    setState((state) => ({
      ...state,
      infoBox: "write a review",
    }));
  }, [setState]);

  // const showWidgetInfoBox = infoBox === "write a review";

  // https://www.tripadvisor.com.my/Restaurant_Review-g298570-d15042368-Reviews-Sausage_KL_Cafe_Deli-Kuala_Lumpur_Wilayah_Persekutuan.html

  const text = "Rate us at Tripadvisor";
  let newText = text.split("\n").map((i) => {
    return <li style={{ listStyle: "none" }}> {i}</li>;
  });

  return (
    <div>
      <FadeIn left by={300} delayBy={0.15} easeTiming={1}>
        <br/>
        <br/>
        <TripadvisorLogoButton href={"https://www.tripadvisor.com.my/Restaurant_Review-g298570-d15042368-Reviews-Sausage_KL_Cafe_Deli-Kuala_Lumpur_Wilayah_Persekutuan.html/#REVIEWS"} target="_blank">
          <Tripadvisor />
          <u>{newText}</u>
        </TripadvisorLogoButton>
        <br/>
        <br/>
        <br/>
      </FadeIn>
      {/* Pop-up info */}
      {/* 
      <ConditionallyRender
        ifTrue={showWidgetInfoBox}
        show={
          <InformationBox setState={setState}>
            <p className={styles.infoBoxParagraph}>
              To use your own components in the chatbot, first you need to
              define it in the "widget" section of the config file:
            </p>
            <Gist id="d1ca7820bfa6a0f8b66a8a5d86a09d8e" />
            <p className={styles.infoBoxParagraph}>
              You will then be able to use the widget when you send a response
              with createChatBotMessage:
            </p>
            <Gist id="6f0c3aeed1ef5b87b4d5652d69e3419d" />
          </InformationBox>
        }
      />
      */}
    </div>
  );
};

export default TripadvisorDocs;
