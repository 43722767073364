// To memoise the selectorimport 
import {createSelector} from 'reselect';

// get state selector
const selectUser = state => state.user;

export const selectCurrentUser = createSelector(
  // first argument can be an array of inputselector 
  // (or can be not an array)
  [selectUser],
  // function to get the input of the above selector
  (user) => user.currentUser
);