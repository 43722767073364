// Working hours

import React, { useEffect } from "react";
import { FadeIn } from "react-anim-kit";
// import { ReactComponent as ActionProviderOverview } from "../../../../../assets/img/actionprovider-overview.svg";

// import styles from "./deals.styles.css";
import "./deals.styles.css";
import styled from "styled-components";

// list-style: https://developer.mozilla.org/en-US/docs/Web/CSS/list-style

const Content = styled.span`
  text-align: center;
  color: purple;
  
`;

const ActionProviderDocs = ({ setState }) => {
  useEffect(() => {
    setState((state) => ({
      ...state,
      infoBox: "deals",
    }));
  }, [setState]);

  // const showActionProviderInfoBox = infoBox === "deals";

  // ❌ Source: https://getemoji.com/#food-drink
  const text = "📣 Deals of the Day!\n27 Aug 2020 Thursday\nAll sausages are having 10% off!";
  let newText = text.split("\n").map((i) => {
    return <li style={{ listStyle: "none", letterSpacing: "1px", lineHeight: "2" }}> {i} </li>;
  });

  return (
    <div>
      <FadeIn down by={200} delayBy={0.10} easeTiming={1}>
        <br/>
        <Content>{newText}</Content>
        <br/>
      </FadeIn>
    </div>
  );
};

export default ActionProviderDocs;
