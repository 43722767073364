class ActionProvider {
  constructor(createChatBotMessage, setStateFunc) {
    this.createChatBotMessage = createChatBotMessage;
    this.setState = setStateFunc;
  }

  // 5 handlers ****************************************************************************************
  // https://fredrikoseberg.github.io/react-anim-kit-docs/#/docs
  // https://fredrikoseberg.github.io/react-chatbot-kit-docs/
  handleCafeMenuDocs = () => {
    const messages = [
      this.createChatBotMessage(
      "We always keep our café menu up to date. Here is the latest café menu for you 😊",
        { widget: "cafeMenuWidget", withAvatar: true },
      ),
      this.createChatBotMessage(
        "Cool! Anything else? If nothing else, have a nice day 😊",
        { delay: 2000, widget: "overview", withAvatar: true }
      ),
    ]

    this.addMessageToBotState(messages);
  };

  handleDeliveryMenuDocs = () => {
    const messages = [
      this.createChatBotMessage(
        "We always keep our delivery menu up to date. Here is the latest delivery menu for you 😊",
        { widget: "deliveryMenuWidget", withAvatar: true }
      ),
      this.createChatBotMessage(
        "Awesome! Anything else? If nothing else, see you around 👋",
        { delay: 2000, widget: "overview", withAvatar: true }
      ),
    ]

    this.addMessageToBotState(messages);
  };

  handleWorkingHoursDocs = () => {
    const messages = [
      this.createChatBotMessage(
        "Our Sausage KL's working hours are listed below 😊",
        { widget: "workingHoursWidget", withAvatar: true }
      ),
      this.createChatBotMessage(
        "Looking forward to seeing you! If there isn't anything else, have a wonderful day 👋",
        { delay: 3000, widget: "overview", withAvatar: true }
      ),
    ];

    this.addMessageToBotState(messages);
  };

  handleDealsDocs = () => {
    const messages = [
      this.createChatBotMessage(
        "Are you excited? 😋 The deals of the day are shown below.",
        { widget: "dealsWidget", withAvatar: true }
      ),
      this.createChatBotMessage(
        "Thank you! Anything else you are looking for? If nothing else, see you again 😄",
        { delay: 2000, widget: "overview", withAvatar: true }
      ),
    ]

    this.addMessageToBotState(messages);
  };

  handleTripadvisorDocs = () => {
    const messages = [
      this.createChatBotMessage(
      "Please drop us a review on Tripadvisor 😊 We are thankful for your feedback.",
      { widget: "tripadvisorWidget", withAvatar: true }
    ),
      this.createChatBotMessage(
        "Thank you so much! 😄 Anything else you are looking for? Otherwise, have a great day 👋",
        { delay: 2000, widget: "overview", withAvatar: true }
      ),
    ]
    
    this.addMessageToBotState(messages);
  };

  // handleBankAccountDocs = () => {
  //   const messages = [
  //     this.createChatBotMessage(
  //     "😊 Thank you for your support.",
  //     { widget: "bankAccountWidget", withAvatar: true }
  //   ),
  //     this.createChatBotMessage(
  //       "Thank you so much! 😄 Anything else you are looking for? Otherwise, have a great day 👋",
  //       { delay: 2000, widget: "overview", withAvatar: true }
  //     ),
  //   ]
    
  //   this.addMessageToBotState(messages);
  // };

  handleMenuDefault = () => {
    const message = this.createChatBotMessage(
      "Hmm... 🤔 There are actually 2 menus. Please try \"cafe\" or \"delivery\".",
      {
        withAvatar: true,
        widget: "overview",
      }
    );

    this.addMessageToBotState(message);
  };
  
  handleDefault = () => {
    const message = this.createChatBotMessage(
      "Hmm... 🤔 I don't understand your question. Try me again with different words or select one of the topics below.",
      {
        withAvatar: true,
        widget: "overview",
      }
    );

    this.addMessageToBotState(message);
  };

  // ****************************************************************************************
  addMessageToBotState = (messages, newState) => {
    if (Array.isArray(messages)) {
      this.setState((state) => ({
        ...state,
        ...newState,
        messages: [...state.messages, ...messages],
        gist: "",
        infoBox: "",
      }));
    } else {
      this.setState((state) => ({
        ...state,
        ...newState,
        messages: [...state.messages, messages],
        gist: "",
        infoBox: "",
      }));
    }
  };
}

// To main App.js
export default ActionProvider;
