import React from "react";
import { Container } from "react-bootstrap";
import "./checkout.success.styles.scss";

const CheckoutSuccesssMessage = () => {
  // Firebase.utils.js
  return (
    <Container>
      <h3 className="success-checkout-main-header">Order successfully!</h3>
      <p className="success-checkout-description pt-3">
        We will be contacting you soon.
        {/* <br/>
        Orders will be processed within 1-2 workings days. */}
        {/* <br />
        <br />
        <u>
          For <strong>Bank transfer</strong>
        </u>
        <br />
        <br />
        Kindly proceed with the payment via your bank to our account.
        <br />
        <em>• Maybank account name: Matt Kerr</em>
        <br />
        <em>• Maybank account number: 101010-10101-11</em>
        <br />
        <br />
        Please note that: <strong> your orders will only be processed within 1-2 days after your payment is successful</strong>
      </p>
      <p className="success-checkout-description pt-3">
        <u>
          For <strong>Cash on delivery</strong>
        </u>
        <br />
        <br />
        We will in contact with you very soon via your phone number provided.
      </p>
      <p className="success-checkout-description pt-3">
        <u>
          <strong>Extras</strong>
        </u>
        <br />
        <br />
        Our bank account can be found via chatbot or this site.
        <br />
        May also refer to our delivery protocols on this site. */}
      </p>
    </Container>
  );
};

export default CheckoutSuccesssMessage;


