import React from "react";
import "./whatsapp.styles.sass";

export const WhatsappIcon = () => {
  return (
    <a href="https://api.whatsapp.com/send?phone=+60176056457&text=Hola%21%20Quisiera%20m%C3%A1s%20informaci%C3%B3n%20sobre%20Varela%202." className="float" target="_blank" rel="noopener noreferrer">
      <i className="fa fa-whatsapp float_further"></i>
    </a>
  );
};
